<!--包厢类模板-->
<template>
  <div class="room">
    <div class="search_box">
      <el-button type="primary" plain size="medium" @click="add">新增模板</el-button>
    </div>

    <!-- 表格 -->
    <el-table :data="tableData" border :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }">
      <el-table-column label="模板名称" align="center" prop="templateName">
      </el-table-column>
      <el-table-column label="单价单位" align="center" prop="pricingTypeName">
      </el-table-column>
      <el-table-column label="默认取消时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.orderCancelNum+ scope.row.orderCancelUnitName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="默认保洁时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.cleanNum+ scope.row.cleanUnitName}}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="infoShow" :title="title" :close-on-click-modal="false" width="40%" center @close="close">
      <el-form :model="baseForm" :rules="rules" ref="baseForm" label-width="200px">
        <el-form-item label="模板名称" prop="templateName">
          <el-input v-model="baseForm.templateName" placeholder="请输入模板名称"></el-input>
        </el-form-item>
        <el-form-item label="单位单价" prop="pricingType">
          <el-select v-model="baseForm.pricingType" placeholder="请选择单价单位" @change="reload">
            <el-option v-for="item in ObjToOpt(priceTypeObj)" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单默认取消时间" prop="orderCancelNum">
          <el-input placeholder="请输入取消时间" v-model="baseForm.orderCancelNum" class="input-with-select" @keydown.native="(e) =>
      (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
        ? false
        : e.returnValue)
        " @input="numberInput">
            <el-select v-model="baseForm.orderCancelUnit" slot="append" placeholder="请选择">
              <el-option v-for="item in ObjToOpt(timeUnit)" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="默认保洁时间" prop="cleanNum">
          <el-input placeholder="请输入保洁时间" v-model="baseForm.cleanNum" class="input-with-select" @keydown.native="(e) =>
      (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
        ? false
        : e.returnValue)
        " @input="numberInput">
            <el-select v-model="baseForm.cleanUnit" slot="append" placeholder="请选择">
              <el-option v-for="item in ObjToOpt(timeUnit)" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
      

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { priceTypeObj, timeUnit, depositUnit } from "@/db/objs";
import { rules } from "@/db/rules";
import { ObjToOpt } from "@/utils/utils";
import {addService,serviceList,editService} from '@/api/setting/service.js'
export default {
  data() {
    return {
      priceTypeObj,
      ObjToOpt,
      timeUnit,
      rules,
      depositUnit,
      tableParams: {
        serviceTemplateType:1,
      },
      tableData: [],
      infoShow: false,
      title: "新增模板",
      baseForm: {
        serviceTemplateType:1,
        orderCancelUnit: 1,
        cleanUnit: 1,
        depositUnit: 1
      },
     
    };
  },

  mounted() {
    this.getTableList();
  },
  methods: {
    // 获取等级列表
    getTableList() {
      serviceList(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data;
        }
      });
    },
    close() {
      this.$refs['baseForm'].clearValidate();
    },
    reset() {
      this.baseForm = {
        serviceTemplateType:1,
        templateName:'',
        pricingType:'',
        orderCancelNum:'',
        cleanNum:'',
        depositNum:'',
        orderCancelUnit: 1,
        cleanUnit: 2,
        depositUnit: 1
      }
    },
    // 【监听】表格重载
    reload() {
      this.$refs['baseForm'].clearValidate();
      this.getTableList();
    },


    add() {
      this.reset()
      this.infoShow = true;
      this.title = "新增模板";
    },
    edit(row) {
      this.infoShow = true;
      this.title = "编辑模板";
      this.baseForm = row;
    },
    numberInput(value) {
      if (value.length > 8) { this.baseForm.experience = value.slice(0, 8) }
    },
    onSubmit() {
      this.$refs.baseForm.validate((valid) => {
        if (valid) {
          // 表单验证通过，执行提交逻辑
          if (this.baseForm.templateId) {
            editService(this.baseForm).then(res => {
              this.reload();
            })
          } else {
            addService(this.baseForm).then(res => {
            
              this.reload();
            })
          }

          this.infoShow = false;
        } else {
          // 表单验证失败，提示用户
          // this.$message.error("表单验证失败，请检查输入！");
          return false;
        }
      });
    }

  },
};
</script>
<style lang="scss" scoped>
.room {
  padding: 0.15rem;
  box-sizing: border-box;

  ::v-deep.input-with-select .el-input-group__append {
    background-color: #fff;

    .el-select .el-input {
      width: 90px;
    }
  }

  ::v-deep .el-input {
    width: 110%;
  }

  ::v-deep .el-input-group {
    width: 110%;
  }
}

.search_box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.15rem;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
</style>
